import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import bikeMapThumb from '../assets/images/bike-map/thumbnail.jpg';
import bikeMapFull1 from '../assets/images/bike-map/full1.jpg';
import bikeMapFull2 from '../assets/images/bike-map/full2.png';
import bikeMapFull3 from '../assets/images/bike-map/full3.png';
import bikeMapFull4 from '../assets/images/bike-map/full4.png';
import bikeMapFull5 from '../assets/images/bike-map/full5.png';
import bikeMapFull6 from '../assets/images/bike-map/full6.png';
import bikeMapFull7 from '../assets/images/bike-map/full7.png';

import petDetectiveThumb from '../assets/images/pet-detective/thumbnail.png';
import petDetective1 from '../assets/images/pet-detective/full1.png';
import petDetective2 from '../assets/images/pet-detective/full2.png';
import petDetective3 from '../assets/images/pet-detective/full3.png';
import petDetective4 from '../assets/images/pet-detective/full4.png';


const DEFAULT_IMAGES = [
  {
    id: '1',
    thumbnail: bikeMapThumb,
    fulls: [
      {
        id: '1',
        source: bikeMapFull1,
        thumbnail: bikeMapFull1,
        caption: 'Bike Map',
        description: 'Full 1 PJ',
      },
      {
        id: '2',
        source: bikeMapFull2,
        thumbnail: bikeMapFull2,
        caption: 'Bike Map',
        description: 'Full 2 PJ',
      },
      {
        id: '3',
        source: bikeMapFull3,
        thumbnail: bikeMapFull3,
        caption: 'Bike Map',
        description: 'Full 3 PJ',
      },
      {
        id: '4',
        source: bikeMapFull4,
        thumbnail: bikeMapFull4,
        caption: 'Bike Map',
        description: 'Full 4 PJ',
      },
      {
        id: '5',
        source: bikeMapFull5,
        thumbnail: bikeMapFull5,
        caption: 'Bike Map',
        description: 'Full 5 PJ',
      },
      {
        id: '6',
        source: bikeMapFull6,
        thumbnail: bikeMapFull6,
        caption: 'Bike Map',
        description: 'Full 6 PJ',
      },
      {
        id: '7',
        source: bikeMapFull7,
        thumbnail: bikeMapFull7,
        caption: 'Bike Map',
        description: 'Full 7 PJ',
      },
    ],
    caption: 'Bike Map',
    description:
      'Share custom bike routes while enjoying fitness, leisure, and commuting routes from your community.',
  },
  {
    id: '2',
    thumbnail: petDetectiveThumb,
    fulls: [{
        id: '1',
        source: petDetective1,
        thumbnail: petDetective1,
        caption: 'Login Page',
        description: 'Login with your Google Account',
      },
      {
        id: '2',
        source: petDetective2,
        thumbnail: petDetective2,
        caption: 'Pet Search',
        description: 'Search for your lost pet and view on map of your area',
      },
      {
        id: '3',
        source: petDetective3,
        thumbnail: petDetective3,
        caption: 'Add A Lost Pet',
        description: 'Help someone find their lost pet with a description and location found',
      },
      {
        id: '4',
        source: petDetective4,
        thumbnail: petDetective4,
        caption: 'View Lost Pets',
        description: 'Discover lost pets in close proximity',
      },
    ],
    caption: 'Pet Detective',
    description: 'Providing a hub  to report lost and found pets in your neighborhoods and cities',
  },
]

class HomeIndex extends React.Component {

  render() {
    const siteTitle = "Patrick Brown Jr"
    const siteDescription = "Personal Website"

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>
        <div id="main">
          <section id="one">
            <header className="major">
              <h2>Hi, I'm Patrick Brown Jr, a software engineer at <a href="https://luc.id" target="_blank"><strong>Lucid</strong></a> in the great city of New Orleans. Outside of my day job, I enjoy collaborating with great people to build cool websites, apps, and more. While you're here, check out some past work, connect through Instagram, or shoot me a message.</h2>
            </header>
            {/* <ul className="actions">
                <li><a href="#" className="button">Learn More</a></li>
            </ul> */}
          </section>
          <section id="two">
              <h2>Recent Work</h2>
              <Gallery images={DEFAULT_IMAGES} />
              {/* <ul className="actions">
                  <li><a href="#" className="button">Full Portfolio</a></li>
              </ul> */}
          </section>
          {/* <section id="three">
              <h2>Get In Touch</h2>
              <div className="row">
                  <div className="8u 12u$(small)">
                      <form method="post" action="mailto:contact@patrickbrownjr.me">
                          <div className="row uniform 50%">
                              <div className="6u 12u$(xsmall)"><input type="text" name="name" id="name" placeholder="Name" /></div>
                              <div className="6u 12u$(xsmall)"><input type="email" name="email" id="email" placeholder="Email" /></div>
                              <div className="12u"><textarea name="message" id="message" placeholder="Message" rows="4"></textarea></div>
                          </div>
                      </form>
                      <ul className="actions">
                          <li><input type="submit" value="Send Message" /></li>
                      </ul>
                  </div>
                  <div className="4u 12u$(small)">
                      <ul className="labeled-icons">
                          <li>
                              <h3 className="icon fa-home"><span className="label">Address</span></h3>
                              1234 Somewhere Rd.<br />
                              Nashville, TN 00000<br />
                              United States
                          </li>
                          <li>
                              <h3 className="icon fa-mobile"><span className="label">Phone</span></h3>
                              000-000-0000
                          </li>
                          <li>
                              <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                              <a href="#"></a>
                          </li>
                      </ul>
                  </div>
              </div>
          </section> */}
        </div>
      </Layout>
    )
  }
}

export default HomeIndex