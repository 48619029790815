import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Carousel, { Modal, ModalGateway } from "react-images";

class Gallery extends Component {
  constructor () {
    super();

    this.state = {
      lightboxIsOpen: false,
      selectedIndex: 0
    };
    
    this.toggleLightbox = this.toggleLightbox.bind(this);
  }
  toggleLightbox(selectedIndex) {
    console.log('Selected Index', selectedIndex)
    this.setState(state => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex
    }));
  }
  renderGallery (images) {
    if (!images) return;

    const gallery = images.map((image, i) => {
      return (
        <article className="6u 12u$(xsmall) work-item"
          // style={{width: '14em', height: 'auto'}}
          key={image.id}>
          <a
            className="image fit thumb"
            href={image.thumbnail}
            onClick={e => {
              e.preventDefault();
              this.toggleLightbox(i);
            }}
          >
            <img src={image.thumbnail} alt=""/>
          </a>

          <h3>{image.caption}</h3>
          <p>{image.description}</p>
        </article>
      );
    });

    return (
      <div className="row">
        {gallery}
      </div>
    );
  }
  render () {
    const { images } = this.props;
    const { selectedIndex, lightboxIsOpen } = this.state;
    console.log('PJ', selectedIndex, lightboxIsOpen, images)
    return (
      <div>
        {this.renderGallery(images)}
        <ModalGateway>
          {lightboxIsOpen && (
            <Modal onClose={this.toggleLightbox}>
              <Carousel currentIndex={selectedIndex} views={images[selectedIndex].fulls} />
            </Modal>
          )}
        </ModalGateway>
      </div>
    );
  }
}

Gallery.displayName = 'Gallery';
Gallery.propTypes = {
  images: PropTypes.array
};

export default Gallery;